<template>
  <div class="index">
    <!-- 首页 -->
    <div class="regionTopImg" :style="{backgroundImage: `url(${bannerLogo})`}">
      <!-- {{$store.state.settingsInfo.bannerLogo}} -->
      <!-- <img :src="$store.state.settingsInfo.bannerLogo" alt=""> -->
    </div>
    <div class="region">

      <div class="contentRegion">
        <div class="item" @click="choice('registerUser')">
          <img src="@/assets/home/xlcp.png" alt="">
          <span>心理测评</span>
        </div>
        <div class="item" @click="choice('report')">
          <img src="../../assets/home/cpbg.png" alt="">
          <span>测评报告</span>
        </div>
        <div v-show="settingInfo.isReserve" class="item" @click="choice('recordManagement')">
          <img src="../../assets/home/yyzx.png" alt="">
          <span>预约咨询</span>
        </div>
        <div class="item" @click="choice('personal')">
          <img src="@/assets/home/grzx.png" alt="">
          <span>个人中心</span>
        </div>
      </div>
    </div>
    <div class="bottomBanner"></div>
  </div>
</template>

<script>
// import { userInfo } from 'os';
import { getSetting } from "@/api/setting";
import {mapActions} from 'vuex'
export default {
  methods: {
    ...mapActions(['getInfo']),
    choice(val) {
      if (localStorage.getItem('roleId') !== '2' && val === 'registerUser') {
        this.$router.push('/home/evaluation');
      } else {
        this.$router.push(`/home/${val}`);
      }
    },
      // 获取配置信息
      async getLogs() {
      const res = await getSetting();
      console.log(res);
      if (res.code === 200) {
        this.settingInfo = res.data;
      }
    },
  },
  data() {
    return{
      bannerLogo: null,
      settingInfo:{
        isReserve: false
      }
    }
  },
  created() {
    this.getLogs();
    // this.bannerLogo = this.$store.state.settingsInfo.bannerLogo;
    // console.log(this.bannerLogo)
    this.bannerLogo = sessionStorage.getItem('banner')
  }
}
</script>

<style lang="scss" scoped>
.index {

  // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
  // height: 100%;
  .contentRegion {
    background: white;
    padding-top: 20px;

    // height: 100%;
    // height: 100%;

    .item {
      margin: auto;
      // box-shadow: 0px 2px 4px 0px rgba(148, 146, 146, 0.25);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1700);
      height: 110px;
      width: 650px;
      margin-bottom: 20px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-weight: 400;
      }

      img {
        margin-right: 5px;
        width: 40px;
        // height: 35px;
      }

      &:nth-child(1) {
        color: #0097FF;
      }

      &:nth-child(2) {

        color: #DB7144;
      }

      // &:nth-child(3) {

      //   color: #6F5CC4;
      // }

      &:nth-child(3) {

        color: #C558BE;
      }

      &:nth-child(4) {

        color: #6F5CC4;
      }
    }
  }
  .bottomBanner{
    width: 900px;
height: 100px;
// background: var(--custom-color);
background: url('../../assets/home/侧边导航备份\ 5.jpg');
background-size: cover;
  }
}

@media screen and (min-width: 751PX) {
  .index {
    .top {
      height: 80px;
    }

    // background-image: url('../../assets/home/backgroundImage.png');
    // background-repeat: no-repeat;
    // background-position: center;
    // background-attachment: fixed;
    background-size: cover;
    // display: flex;
    // justify-content: center;
  }

  .regionTopImg {
    height: 200px;
    width: 100%;
    img{
      height: 100%;
    }
    // background-image: url('http://user.yuanaiconsulting.com/imessage/banner.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;

    .region {

      // width: 900px;
      // height: calc(100vh - 80px);
      // background: white;

      // background-size: 900px;
      img {
        height: 200px;
        width: 100%;
        // margin: 0;
        // padding: 0;
      }
    }

    // .contentRegion {
    //   height: calc(100% - 110px);
    //   // height: 100%;
    // }
  }
}

@media screen and (max-width: 750PX) {
  .regionTopImg {
    height: 150px;
    // background-image: url('http://user.yuanaiconsulting.com/imessage/banner.png');
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    img{
      height: 100%;
      width: 100%;
    }

    // img {
    //   height: 150px;
    //   width: 100%;
    // }
  }

  .region {
    width: 100%;



    .contentRegion {
      background: white;

      .item {
        width: 80%;
      }
    }
  }
}
</style>